import React from "react";
import { Article } from "./types";
import Link from "components/Common/Link";

type Props = {
  className?: string;
};

export default function ArticleLoading({ className }: Props) {
  return (
    <div className={`relative w-full p-4 animate-pulse ${className}`}>
      <div className="container mb-4">
        <h2 className="text-2xl w-full bg-gray-500">&nbsp;</h2>
      </div>

      <div className="w-full mb-4 text-sm bg-gray-500">&nbsp;</div>

      <div className="w-full h-52 mb-4 article bg-gray-500">&nbsp;</div>
    </div>
  );
}
