import React, { MouseEventHandler } from "react";
import Icon from "./Icon";

const goBack: MouseEventHandler = (e) => {
  e.preventDefault();
  window.history.back();
};

type Props = {
  show?: boolean;
  className?: string;
};

export default function GoBack({ show, className }: Props) {
  if (!show) {
    return <></>;
  }
  return (
    <a
      className={`fixed z-50 bg-background-300 text-foreground-400 lg:bg-transparent rounded-full flex w-10 h-10 lg:w-12 lg:h-12 text-4xl items-center justify-center bottom-10 left-3 lg:top-2 lg:left-64 ${className}`}
      href="#"
      onClick={goBack}
    >
      <Icon icon="chevron-left" />
    </a>
  );
}
