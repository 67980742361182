import React from "react";
import Layout from "layouts/Layout";
import Loading from "components/News/ArticleLoading";

export default function NotFound() {
  return (
    <Layout title="Test">
      <div className="container mx-4 my-12 px-4">
        <div className="flex content-center items-center justify-center h-full">
          <div className="w-full px-4">
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg border-0">
              <div className="text-center my-12 w-full">
                <h1 className="text-gray-700 text-3xl">Test</h1>
              </div>
              <div className="w-full text-gray-600 text-center mb-3 font-bold">
                <Loading />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
