import React from "react";
import Link from "components/Common/Link";
import config from "config/site";

export default function Footer() {
  return (
    <div className="text-sm bg-background-200 border-background-300 border-t pt-6">
      <div className="container mx-auto py-4 px-5 flex flex-wrap flex-col sm:flex-row">
        <p className="text-foreground-300 text-center sm:text-left">
          © 2022 ThangisWho
        </p>
        <span className="inline-flex sm:ml-auto sm:mt-0 mt-2 justify-center sm:justify-start">
          {config.menus.map((menu: any, i: number) => {
            return (
              <Link
                key={i}
                href={menu.href}
                className="ml-4 text-foreground-300"
              >
                {menu.title}
              </Link>
            );
          })}
        </span>
      </div>
    </div>
  );
}
