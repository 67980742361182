import React, { ReactNode, MouseEventHandler } from "react";

// components
import Footer from "components/Footers/Footer";
import Navbar from "components/Navbars/Navbar";
import SEO from "components/Common/SEO";
import IconGoBack from "components/Common/IconGoBack";
import IconGoTop from "components/Common/IconGoTop";

type Props = {
  title?: string;
  path?: string;
  description?: string;
  backButton?: boolean;
  children: ReactNode;
};

export default function Layout({
  title,
  path,
  description,
  backButton = false,
  children,
}: Props) {
  return (
    <>
      <SEO title={title} path={path} description={description} />
      <Navbar />
      <div>
        <div className="pb-10">{children}</div>
        <IconGoBack show={backButton} />
        <IconGoTop />
        <Footer />
      </div>
    </>
  );
}
