import React, { useState } from "react";
import Link from "components/Common/Link";
import Brand from "components/Common/Brand";
import config from "config/site";

type Props = {
  className?: string;
};

export default function Navbar({ className = "" }: Props) {
  const [sidebarShow, setSidebar] = useState(false);

  return (
    <>
      <nav
        className={`fixed md:sticky w-full z-50 text-sm font-medium shadow ${className}`}
      >
        <div className="py-3">
          <div className="md:flex md:items-center md:justify-between">
            <div className="pl-3 pr-1 flex items-center justify-between flex-initial">
              <div className="text-sm font-semibold text-left uppercase">
                <Link href="/" className="py-2 sm:py-1">
                  <Brand className="h-6 fill-current brand" />
                </Link>
              </div>

              <div className="flex mr-4 md:hidden">
                <button
                  type="button"
                  className="text-foreground-200 hover:text-foreground-100 focus:outline-none focus:text-foreground-100"
                  aria-label="toggle menu"
                  onClick={() => setSidebar(!sidebarShow)}
                >
                  <svg viewBox="0 0 24 24" className="w-6 h-6 fill-current">
                    <path
                      fillRule="evenodd"
                      d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
                    ></path>
                  </svg>
                </button>
              </div>
            </div>

            <div
              className={`menu w-full flex-grow absolute md:relative md:flex md:flex-1 items-center justify-between bg-background-200 ${
                sidebarShow ? "flex" : "hidden"
              }`}
            >
              <div className="flex flex-col flex-grow md:flex-row items-center py-6 md:py-0 md:mx-8">
                {config.menus.map((menu: any, i: number) => {
                  return (
                    <Link
                      key={i}
                      href={menu.href}
                      className="w-full md:w-auto flex justify-center px-3 py-2 mr-1 mt-2 rounded-md md:mt-0 text-foreground-200"
                    >
                      {menu.title}
                    </Link>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}
